import React from 'react';
import parse from 'html-react-parser';
import { Input2 } from '../../components';
import { uploadFile } from '../../functions';
import { MuiColorInput } from 'mui-color-input';
import { getAboutData, saveAboutData } from './helper';
import { Done as DoneIcon, DeleteForever } from '@mui/icons-material';
import { Box, Typography, LinearProgress, Button, IconButton, Radio, RadioGroup, FormControlLabel, Switch } from '@mui/material';

const About = () => {

    const inpRef = React.useRef(null);
    const imgIndex = React.useRef(0);

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({
        color: '#FFF',
        tag: 'h1',
        family: 'Barlow',
        align: '',
        s1Title: '',
        s1Image: '',
        s2Title1: '',
        s2Title2: '',
        s2Title2S: '',
        s2Desc: '',
        s2List: [],
        s2Img: '',
        s3Title1: '',
        s3Title2: '',
        s3Title2S: '',
        s3List: [],
        s3Img: '',
        collabTitle: '',
        collabImg: '',
        hide: false, 
    });

    const s2Title2 = React.useMemo(() => data.s2Title2?.toLowerCase()?.replace(data.s2Title2S?.toLowerCase(), `<span>${data.s2Title2S?.toLowerCase()}</span>`));
    const s3Title2 = React.useMemo(() => data.s3Title2?.toLowerCase()?.replace(data.s3Title2S?.toLowerCase(), ''));

    const handleChange = React.useCallback((value) => {
        setData(state => ({ ...state, ...value }));
    }, [setData]);

    const addToList = () => {
        const value = prompt('Add new list') || '';
        if(value?.trim()?.length>0){
            setData(state => ({
                ...state,
                s2List: [...state.s2List, value],
            }));
        }
    };

    const addToList2 = () => {
        const value1 = prompt('Title') || '';
        if(value1?.trim()?.length>0){
            const value2 = prompt('Description') || '';
            if(value2?.trim()?.length){
                setData(state => ({
                    ...state,
                    s3List: [...state.s3List,{ title: value1, desc: value2 }],
                }));
            }
        }
    };

    const removeFromList2 = (n) => {
        setData(state => ({
            ...state,
            s3List: [...state.s3List?.filter((x, i) => i!==n)],
        }));
    };

    const removeFromList = (n) => {
        setData(state => ({
            ...state,
            s2List: [...state.s2List?.filter((x, i) => i!==n)],
        }));
    };

    const handleImage = async(val) => {
        const file= val?.target?.files[0];
        setLoading(true);
        if(file){
            const url = URL.createObjectURL(file);
            const uri = await uploadFile(url, `files/${Date.now()}_${file?.name}`);
            switch(imgIndex.current){
                case 0:
                    setData(state => ({
                        ...state,
                        s1Image: uri
                    }));
                    break;
                case 1:
                    setData(state => ({
                        ...state,
                        s2Img: uri,
                    }));
                    break;
                case 2:
                    setData(state => ({
                        ...state,
                        s3Img: uri,
                    }));
                    break;
                case 3:
                    setData(state => ({
                        ...state,
                        collabImg: uri,
                    }));
                    break;
                default:
                    break;
            }
        }
        setLoading(false);
    };

    const fetch = async() => {
        setLoading(true);
        await getAboutData(setData);
        setLoading(false);
    };

    React.useEffect(() => {
        fetch();
    }, []);

    const section1 = React.useMemo(() => (
        <React.Fragment>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 6 }}>Section 1</Typography>
            <Box width='95%' position='relative'>
                <img src={data.s1Image} className="w-100" alt="banner"/>
                <Box width='100%' position='absolute' bottom={0} zIndex={100} padding={2} display='flex' justifyContent={data.align} id='alif-banner'>
                    {parse(`<${data.tag} style="color: ${data.color}; font-family: ${data.family}; ">${data.s1Title}</${data.tag}>`)}
                </Box>
            </Box>
            <Button 
            variant="contained" 
            onClick={() => {
                imgIndex.current = 0;
                inpRef.current?.click();
            }} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 2 }}>Change image</Button>
            <Box height={20}/>
            <br/>
            <Input2 placeholder='Title' value={data.s1Title} onChange={e => handleChange({ s1Title: e })}/>
            <Box height={6}/>
            <MuiColorInput format="hex" value={data.color} onChange={e => handleChange({ color: e })}/>
            <RadioGroup row defaultValue="Barlow" name="radio-buttons-group" value={data.family} onChange={e => handleChange({ family: e.target.value })}>
                <FormControlLabel value="Barlow" control={<Radio />} label="Barlow" />
                <FormControlLabel value="Playfair Display" control={<Radio />} label="Playfair Display" />
            </RadioGroup>
            <RadioGroup row defaultValue="h1" name="radio-buttons-group" value={data.tag} onChange={e => handleChange({ tag: e.target.value })}>
                <FormControlLabel value="h1" control={<Radio />} label="h1" />
                <FormControlLabel value="h2" control={<Radio />} label="h2" />
                <FormControlLabel value="h3" control={<Radio />} label="h3" />
                <FormControlLabel value="h4" control={<Radio />} label="h4" />
                <FormControlLabel value="h5" control={<Radio />} label="h5" />
                <FormControlLabel value="h6" control={<Radio />} label="h6" />
            </RadioGroup>
            <RadioGroup row defaultValue="flex-start" name="radio-buttons-group" value={data.align} onChange={e => handleChange({ align: e.target.value })}>
                <FormControlLabel value="flex-start" control={<Radio />} label="left" />
                <FormControlLabel value="center" control={<Radio />} label="center" />
                <FormControlLabel value="flex-end" control={<Radio />} label="right" />
            </RadioGroup>
        </React.Fragment>
    ));

    const section2 = React.useMemo(() => (
        <React.Fragment>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 6, mt: 6 }}>Section 2</Typography>
            <Box width='95%'>
                <section className="about cover section-padding mt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mb-30">
                                <div className="section-subtitle">{data.s2Title1}</div>
                                <div className="section-title">{parse(s2Title2)}</div>
                                <p>{data.s2Desc}</p>
                                <ul className="list-unstyled about-list mb-30">
                                    {data.s2List?.map((obj, i) => (
                                        <li key={i}>
                                            <div className="about-list-icon">
                                                <span className="fas fa-check" style={{ borderRadius: 15, width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <DoneIcon sx={{ color: '#FFF', width: '14px', height: '14px' }}/>
                                                </span>
                                            </div>
                                            <div className="about-list-text">
                                                <p>{obj}</p>
                                            </div>
                                            <IconButton onClick={() => removeFromList(i)}>
                                                <DeleteForever sx={{ color: '#ABABAB' }}/>
                                            </IconButton>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-md-5 offset-md-1 animate-box fadeInUp animated" data-animate-effect="fadeInUp">
                                <div className="img-exp">
                                    <div className="about-img">
                                        <div className="img">
                                            <img src={data.s2Img} className="img-fluid trs" alt="banner"/>
                                        </div>
                                    </div>
                                    <div id="circle">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 300" enableBackground="new 0 0 300 300" xmlSpace="preserve">
                                            <defs>
                                                <path id="circlePath" d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0"></path>
                                            </defs>
                                            <circle cx="150" cy="100" r="75" fill="none"></circle>
                                            <g>
                                                <use xlinkHref="#circlePath" fill="none"></use>
                                                <text fill="#0f2454">
                                                    <textPath xlinkHref="#circlePath">
                                                        {" "}. ALIFTOURS LIMITED . ALIFTOURS{" "}
                                                    </textPath>
                                                </text>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Box>
            <Input2 placeholder='Title 1' sx={{ mt: 2 }} value={data.s2Title1} onChange={e => handleChange({ s2Title1: e })}/>
            <Input2 placeholder='Title 2' sx={{ ml: 4 }} value={data.s2Title2} onChange={e => handleChange({ s2Title2: e })}/>
            <Input2 placeholder='Part of Title 2 to be in secondary color' sx={{ ml: 4 }} value={data.s2Title2S} onChange={e => handleChange({ s2Title2S: e })}/>
            <br/>
            <Input2 multiline placeholder='Description' sx={{ mt: 2 }} value={data.s2Desc} onChange={e => handleChange({ s2Desc: e })}/>
            <br/>
            <Button 
            variant="contained" 
            onClick={addToList} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 4 }}>Add new list</Button>
            <Button 
            variant="contained" 
            onClick={() => {
                imgIndex.current = 1;
                inpRef.current?.click();
            }} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 4, ml: 4 }}>Select image</Button>
            <br/>
            <Input2 multiline placeholder='Collaboration Title' sx={{ mt: 12 }} value={data.collabTitle} onChange={e => handleChange({ collabTitle: e })}/>
            <br/>
            <img width='160' height='auto' alt='logo' src={data.collabImg} style={{ marginTop: 6 }}/>
            <br/>
            <Button 
            variant="contained" 
            onClick={() => {
                imgIndex.current = 3;
                inpRef.current?.click();
            }} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 1 }}>Select Collaboration Logo</Button>
            <br/><br/>
            <FormControlLabel control={<Switch checked={data.hide} onChange={e => handleChange({ hide: e.target.checked })}/>} label="Hide collab" />
        </React.Fragment>
    ));

    const section3 = React.useMemo(() => (
        <React.Fragment>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 6, mt: 6 }}>Section 3</Typography>
            <Box width='95%'>
                <section className="tours1 section-padding bg-lightnav about1" data-scroll-index="1" 
                style={{ background: `url("${data.s3Img}")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-subtitle">
                                    <span className="white1">{data.s3Title1}</span>
                                </div>
                                <div className="section-title">
                                    <span className="white1">{s3Title2}</span><span>{data.s3Title2S}</span>
                                </div>
                            </div>
                        </div>
                        {data.s3List?.map((obj, i) => (
                            <div className="row" key={i} style={{ marginBottom: '16px' }}>
                                <div className="col-md-12">
                                    <p className="white1">
                                        <b>{obj?.title}</b>
                                    </p>
                                    <p className="white1">{obj?.desc}</p>
                                </div>
                                <Button 
                                variant="contained" 
                                onClick={() => removeFromList2(i)}
                                sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 4 }}>Remove</Button>
                            </div>
                        ))}
                    </div>
                </section>
            </Box>
            <Input2 placeholder='Title 1' sx={{ mt: 2 }} value={data.s3Title1} onChange={e => handleChange({ s3Title1: e })}/>
            <Input2 placeholder='Title 2' sx={{ ml: 4 }} value={data.s3Title2} onChange={e => handleChange({ s3Title2: e })}/>
            <Input2 placeholder='Part of Title 2 to be in secondary color' sx={{ ml: 4 }} value={data.s3Title2S} onChange={e => handleChange({ s3Title2S: e })}/>
            <br/>
            <Button 
            variant="contained" 
            onClick={addToList2} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 4 }}>Add new list</Button>
            <Button 
            variant="contained" 
            onClick={() => {
                imgIndex.current = 2;
                inpRef.current?.click();
            }} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 4, ml: 4 }}>Select image</Button>
        </React.Fragment>
    ));

    return (

        <Box className='admin-div-body'>
            {loading?<LinearProgress sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 10000 }}/>:null}
            {section1}
            {section2}
            {section3}
            <input ref={inpRef} id='file-input' type='file' accept='image/*' style={{ display: 'none' }} onChange={e =>  handleImage(e)}/>
            <Button 
            variant="contained" 
            onClick={async() => {
                setLoading(true);
                await saveAboutData(data);
                setLoading(false);
            }} 
            sx={{ color: '#cba235', borderRadius: 30, backgroundColor: '#2e2d31', position: 'absolute', bottom: 20, right: 20, p: 3.5, fontWeight: 'bold' }}>Save</Button>
            <Box height={200}/>
        </Box>

    );

}

export default React.memo(About);