import React from 'react';
import parse from 'html-react-parser';
import { Input2 } from '../../components';
import { uploadFile } from '../../functions';
import { MuiColorInput } from 'mui-color-input';
import { DeleteForever } from '@mui/icons-material';
import { saveHajjData, getHajjData } from './helper';
import { Box, Typography, LinearProgress, Button, IconButton, Radio, RadioGroup, FormControlLabel } from '@mui/material';

const Hajj = () => {

    const inpRef = React.useRef(null);
    const imgIndex = React.useRef(0);

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({
        color: '#FFF',
        tag: 'h1',
        family: 'Barlow',
        align: '',
        s1Title: '',
        s1Image: '',
        s2Title1: '',
        s2Title2: '',
        s2Title2S: '',
        s2Desc: '',
        s3Img: '',
        s3Title1: '',
        s3Title2: '',
        s3Title2S: '',
        s3List: [],
    });

    const handleChange = React.useCallback((value) => {
        setData(state => ({ ...state, ...value }));
    }, [setData]);

    const handleImage = async(val) => {
        const file= val?.target?.files[0];
        setLoading(true);
        if(file){
            const url = URL.createObjectURL(file);
            const uri = await uploadFile(url, `files/${Date.now()}_${file?.name}`);
            switch(imgIndex.current){
                case 0:
                    setData(state => ({
                        ...state,
                        s1Image: uri
                    }));
                    break;
                case 1:
                    setData(state => ({
                        ...state,
                        s3Img: uri,
                    }));
                    break;
                default:
                    break;
            }
        }
        setLoading(false);
    };

    const addToList = () => {
        const value1 = prompt('Title') || '';
        if(value1?.trim()?.length>0){
            const value2 = prompt('Description') || '';
            if(value2?.trim()?.length){
                setData(state => ({
                    ...state,
                    s3List: [...state.s3List,{ title: value1, desc: value2 }],
                }));
            }
        }
    };

    const removeFromList = (n) => {
        setData(state => ({
            ...state,
            s3List: [...state.s3List?.filter((x, i) => i!==n)],
        }));
    };

    const fetch = async() => {
        setLoading(true);
        await getHajjData(setData);
        setLoading(false);
    };

    React.useEffect(() => {
        fetch();
    }, []);

    const s2Title2 = React.useMemo(() => data.s2Title2?.toLowerCase()?.replace(data.s2Title2S?.toLowerCase(), `<span>${data.s2Title2S?.toLowerCase()}</span>`));
    const s3Title2 = React.useMemo(() => data.s3Title2?.toLowerCase()?.replace(data.s3Title2S?.toLowerCase(), `<span>${data.s3Title2S?.toLowerCase()}</span>`));

    const section1 = React.useMemo(() => (
        <React.Fragment>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 6 }}>Section 1</Typography>
            <Box width='95%' position='relative'>
                <img src={data.s1Image} className="w-100" alt="banner"/>
                <Box width='100%' position='absolute' bottom={0} zIndex={100} padding={2} display='flex' justifyContent={data.align} id='alif-banner'>
                    {parse(`<${data.tag} style="color: ${data.color}; font-family: ${data.family}; ">${data.s1Title}</${data.tag}>`)}
                </Box>
            </Box>
            <Button 
            variant="contained" 
            onClick={() => {
                imgIndex.current = 0;
                inpRef.current?.click();
            }} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 2 }}>Change image</Button>
            <Box height={20}/>
            <br/>
            <Input2 placeholder='Title' value={data.s1Title} onChange={e => handleChange({ s1Title: e })}/>
            <Box height={6}/>
            <MuiColorInput format="hex" value={data.color} onChange={e => handleChange({ color: e })}/>
            <RadioGroup row defaultValue="Barlow" name="radio-buttons-group" value={data.family} onChange={e => handleChange({ family: e.target.value })}>
                <FormControlLabel value="Barlow" control={<Radio />} label="Barlow" />
                <FormControlLabel value="Playfair Display" control={<Radio />} label="Playfair Display" />
            </RadioGroup>
            <RadioGroup row defaultValue="h1" name="radio-buttons-group" value={data.tag} onChange={e => handleChange({ tag: e.target.value })}>
                <FormControlLabel value="h1" control={<Radio />} label="h1" />
                <FormControlLabel value="h2" control={<Radio />} label="h2" />
                <FormControlLabel value="h3" control={<Radio />} label="h3" />
                <FormControlLabel value="h4" control={<Radio />} label="h4" />
                <FormControlLabel value="h5" control={<Radio />} label="h5" />
                <FormControlLabel value="h6" control={<Radio />} label="h6" />
            </RadioGroup>
            <RadioGroup row defaultValue="flex-start" name="radio-buttons-group" value={data.align} onChange={e => handleChange({ align: e.target.value })}>
                <FormControlLabel value="flex-start" control={<Radio />} label="left" />
                <FormControlLabel value="center" control={<Radio />} label="center" />
                <FormControlLabel value="flex-end" control={<Radio />} label="right" />
            </RadioGroup>
        </React.Fragment>
    ));

    const section2 = React.useMemo(() => (
        <Box width='100%' position='relative'>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 6, mt: 6 }}>Section 2</Typography>
            <Box width='95%'>
                <section className="about cover section-padding mt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mb-30">
                                <div className="section-subtitle">{data.s2Title1}</div>
                                <div className="section-title">{parse(s2Title2)}</div>
                                <p>{data.s2Desc}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </Box>
            <Input2 placeholder='Title 1' sx={{ mt: 2 }} value={data.s2Title1} onChange={e => handleChange({ s2Title1: e })}/>
            <Input2 placeholder='Title 2' sx={{ ml: 4 }} value={data.s2Title2} onChange={e => handleChange({ s2Title2: e })}/>
            <Input2 placeholder='Part of Title 2 to be in secondary color' sx={{ ml: 4 }} value={data.s2Title2S} onChange={e => handleChange({ s2Title2S: e })}/>
            <br/>
            <Input2 multiline placeholder='Description' sx={{ mt: 2 }} value={data.s2Desc} onChange={e => handleChange({ s2Desc: e })}/>
        </Box>
    ));

    const section3 = React.useMemo(() => (
        <Box width='100%' position='relative'>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 6, mt: 6 }}>Section 3</Typography>
            <Box width='95%'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-5" >
                            <img src={data.s3Img} width="100%"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-subtitle">
                                <span>{data.s3Title1}</span>
                            </div>
                            <div className="section-title">{parse(s3Title2)}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {data.s3List?.map((obj, i) => (
                                <Box key={i}>
                                    <p><b>{obj?.title}: </b>{obj?.desc}</p>
                                    <IconButton onClick={() => removeFromList(i)}>
                                        <DeleteForever sx={{ color: '#ABABAB' }}/>
                                    </IconButton>
                                </Box>
                            ))}
                        </div>
                    </div>
                </div>
            </Box>
            <Box mt={6}/>
            <Input2 placeholder='Title 1' sx={{ mt: 2 }} value={data.s3Title1} onChange={e => handleChange({ s3Title1: e })}/>
            <Input2 placeholder='Title 2' sx={{ ml: 4 }} value={data.s3Title2} onChange={e => handleChange({ s3Title2: e })}/>
            <Input2 placeholder='Part of Title 2 to be in secondary color' sx={{ ml: 4 }} value={data.s3Title2S} onChange={e => handleChange({ s3Title2S: e })}/>
            <br/>
            <Button 
            variant="contained" 
            onClick={() => {
                imgIndex.current = 1;
                inpRef.current?.click();
            }} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 2 }}>Select image</Button>
            <Button 
            variant="contained" 
            onClick={addToList} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 2, ml: 4 }}>Add new list</Button>
        </Box>
    ));

    return (

        <Box className='admin-div-body'>
            {loading?<LinearProgress sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 10000 }}/>:null}
            {section1}
            {section2}
            {section3}
            <input ref={inpRef} id='file-input' type='file' accept='image/*' style={{ display: 'none' }} onChange={e =>  handleImage(e)}/>
            <Button 
            variant="contained" 
            onClick={async() => {
                setLoading(true);
                await saveHajjData(data);
                setLoading(false);
            }} 
            sx={{ color: '#cba235', borderRadius: 30, backgroundColor: '#2e2d31', position: 'absolute', bottom: 20, right: 20, p: 3.5, fontWeight: 'bold' }}>Save</Button>
            <Box height={200}/>
        </Box>

    );

}

export default React.memo(Hajj);