import React from 'react';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import { Loader } from '../../components';
import { getUmrahData } from '../../Admin/Home/helper';

export default function Main() {

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({
        color: '#FFF',
        tag: 'h1',
        family: 'Barlow',
        align: '',
        s1Title: '',
        s1Image: '',
        s2Title1: '',
        s2Title2: '',
        s2Title2S: '',
        s2Desc: '',
        s2Img: '',
        s31Title1: '',
        s31Title2: '',
        s31List: [],
        s32List: [
            {
                img: '',
                title: '',
                subTitle: '',
                desc: '',
            }
        ],
        section3: false, 
    });

    const fetch = async() => {
        setLoading(true);
        await getUmrahData(setData);
        setLoading(false);
    };

    React.useEffect(() => {
        fetch();
    }, []);

    const s2Title2 = React.useMemo(() => data.s2Title2?.toLowerCase()?.replace(data.s2Title2S?.toLowerCase(), `<span>${data.s2Title2S?.toLowerCase()}</span>`));

    if(loading) return <Loader/>;

    return (

        <React.Fragment>
            <Section1 s1Image={data.s1Image} align={data.align} color={data.color} family={data.family} tag={data.tag} title={data.s1Title}/>
            <Section2 s2Title1={data.s2Title1} s2Title2={s2Title2} s2Desc={data.s2Desc} s2Img={data.s2Img}/>
            <Section3 s31List={data.s31List} s31Title1={data.s31Title1} s31Title2={data.s31Title2} s32List={data.s32List} s3={data.section3}/>
        </React.Fragment>

    );

}