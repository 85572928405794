import React from 'react';
import Slider from "react-slick";
import { Section3 } from './umrah2';
import parse from 'html-react-parser';
import { Input2 } from '../../components';
import { uploadFile } from '../../functions';
import "slick-carousel/slick/slick-theme.css";
import { MuiColorInput } from 'mui-color-input';
import { saveUmrahData, getUmrahData } from './helper';
import { Box, Typography, LinearProgress, Button, IconButton, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { Luggage as LuggageIcon, Apartment as ApartmentIcon, DeleteForever, Add, CalendarMonth } from '@mui/icons-material';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true
};

const Umrah = () => {

    const inpRef = React.useRef(null);
    const imgIndex = React.useRef(0);
    const listIndex = React.useRef(0);
    const refArr = React.useRef([]);

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({
        color: '#FFF',
        tag: 'h1',
        family: 'Barlow',
        align: '',
        s1Title: '',
        s1Image: '',
        s2Title1: '',
        s2Title2: '',
        s2Title2S: '',
        s2Desc: '',
        s2Img: '',
        section3: false, 
        s31List: [
            {
                title: '',
                desc: '',
                list: [],
                img: [],
            }
        ],
        s32List: [
            {
                img: [],
                title: '',
                subTitle: '',
                desc: '',
            }
        ],
    });

    const handleChange = React.useCallback((value) => {
        setData(state => ({ ...state, ...value }));
    }, [setData]);

    const handleListChange = React.useCallback((i, obj, val) => {
        setData(state => ({ 
            ...state, 
            s32List: [...state.s32List?.map((item, index) => index===i? { ...item, [obj]: val } : item)]
        }));
    }, [setData]);

    const handleListImage = async(i, val) => {
        const file= val?.target?.files[0];
        setLoading(true);
        if(file){
            const url = URL.createObjectURL(file);
            const uri = await uploadFile(url, `files/${Date.now()}_${file?.name}`);
            setData(state => ({ 
                ...state, 
                s32List: [...state.s32List?.map((item, index) => index===i? { ...item, img: [...item.img, uri] } : item)]
            }));
        }
        setLoading(false);
    };

    const handleImage = async(val) => {
        const file= val?.target?.files[0];
        setLoading(true);
        if(file){
            const url = URL.createObjectURL(file);
            const uri = await uploadFile(url, `files/${Date.now()}_${file?.name}`);
            switch(imgIndex.current){
                case 0:
                    setData(state => ({
                        ...state,
                        s1Image: uri
                    }));
                    break;
                case 1:
                    setData(state => ({
                        ...state,
                        s2Img: uri,
                    }));
                    break;
                case 2:
                    setData(state => ({
                        ...state,
                        s31List: [...state.s31List.map((obj, i) => i===listIndex.current?{ ...obj, img: [...obj.img, uri] }:obj)]
                    }));
                    break;
                default:
                    break;
            }
        }
        setLoading(false);
    };

    const handleList1 = React.useCallback((i, obj, val) => {
        setData(state => ({ 
            ...state, 
            s31List: [...state.s31List?.map((item, index) => index===i? { ...item, [obj]: val } : item)]
        }));
    }, [setData]);

    const addToList1 = () => {
        setData(state => ({
            ...state,
            s31List: [...state.s31List, {
                title: '',
                desc: '',
                list: [],
                img: '',
            }],
        }));
    };

    const adds1SubList = (i) => {
        const value = prompt('Add new list') || '';
        if(value?.trim()?.length>0){
            setData(state => ({
                ...state,
                s31List: [...state.s31List.map((obj, x) => x===i?{ ...obj, list: [...obj.list, value] }:obj)]
            }));
        }
    };

    const removeFroms1SubList = (n, m) => {
        setData(state => ({
            ...state,
            s31List: [...state.s31List.map((obj,i) => i===n?{ ...obj, list: [...obj.list.filter((a,b) => b!==m)] }:obj)],
        }));
    };

    const removeFromList1 = (n) => {
        setData(state => ({
            ...state,
            s31List: [...state.s31List?.filter((x, i) => i!==n)],
        }));
    };

    const addToList2 = () => {
        setData(state => ({
            ...state,
            s32List: [...state.s32List, {
                img: [],
                title: '',
                subTitle: '',
                desc: '',
            }]
        }));
    };

    const removeFromList2 = (n) => {
        setData(state => ({
            ...state,
            s32List: [...state.s32List?.filter((x, i) => i!==n)],
        }));
    };

    const removeS3ListImg = (i, x) => {
        setData(state => ({
            ...state,
            s31List: [...state.s31List.map((obj1, i1) => i1 === i ? {
                ...obj1, 
                img: [...obj1.img.filter((obj2, x1) => x !== x1)]
            } : obj1)],
        }));
    };

    const removeAccListImg = (i, x) => {
        setData(state => ({
            ...state,
            s32List: [...state.s32List.map((obj1, i1) => i1 === i ? {
                ...obj1, 
                img: [...obj1.img.filter((obj2, x1) => x !== x1)]
            } : obj1)],
        }));
    };

    const fetch = async() => {
        setLoading(true);
        await getUmrahData(setData);
        setLoading(false);
    };

    React.useEffect(() => {
        fetch();
    }, []);

    const s2Title2 = React.useMemo(() => data.s2Title2?.toLowerCase()?.replace(data.s2Title2S?.toLowerCase(), `<span>${data.s2Title2S?.toLowerCase()}</span>`));

    const section1 = React.useMemo(() => (
        <React.Fragment>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 6 }}>Section 1</Typography>
            <Box width='95%' position='relative'>
                <img src={data.s1Image} className="w-100" alt="banner"/>
                <Box width='100%' position='absolute' bottom={0} zIndex={100} padding={2} display='flex' justifyContent={data.align} id='alif-banner'>
                    {parse(`<${data.tag} style="color: ${data.color}; font-family: ${data.family}; ">${data.s1Title}</${data.tag}>`)}
                </Box>
            </Box>
            <Button 
            variant="contained" 
            onClick={() => {
                imgIndex.current = 0;
                inpRef.current?.click();
            }} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 2 }}>Change image</Button>
            <Box height={20}/>
            <br/>
            <Input2 placeholder='Title' value={data.s1Title} onChange={e => handleChange({ s1Title: e })}/>
            <Box height={6}/>
            <MuiColorInput format="hex" value={data.color} onChange={e => handleChange({ color: e })}/>
            <RadioGroup row defaultValue="Barlow" name="radio-buttons-group" value={data.family} onChange={e => handleChange({ family: e.target.value })}>
                <FormControlLabel value="Barlow" control={<Radio />} label="Barlow" />
                <FormControlLabel value="Playfair Display" control={<Radio />} label="Playfair Display" />
            </RadioGroup>
            <RadioGroup row defaultValue="h1" name="radio-buttons-group" value={data.tag} onChange={e => handleChange({ tag: e.target.value })}>
                <FormControlLabel value="h1" control={<Radio />} label="h1" />
                <FormControlLabel value="h2" control={<Radio />} label="h2" />
                <FormControlLabel value="h3" control={<Radio />} label="h3" />
                <FormControlLabel value="h4" control={<Radio />} label="h4" />
                <FormControlLabel value="h5" control={<Radio />} label="h5" />
                <FormControlLabel value="h6" control={<Radio />} label="h6" />
            </RadioGroup>
            <RadioGroup row defaultValue="flex-start" name="radio-buttons-group" value={data.align} onChange={e => handleChange({ align: e.target.value })}>
                <FormControlLabel value="flex-start" control={<Radio />} label="left" />
                <FormControlLabel value="center" control={<Radio />} label="center" />
                <FormControlLabel value="flex-end" control={<Radio />} label="right" />
            </RadioGroup>
        </React.Fragment>
    ));

    const section2 = React.useMemo(() => (
        <React.Fragment>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 6, mt: 6 }}>Section 2</Typography>
            <Box width='95%'>
                <section className="about cover section-padding mt-0 pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mb-30">
                                <img src={data.s2Img} width="100%"/>
                            </div>
                            <div className="col-md-6 mb-30">
                                <div className="section-subtitle">{data.s2Title1}</div>
                                <div className="section-title">{parse(s2Title2)}</div>
                                <p>{data.s2Desc}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </Box>
            <Input2 placeholder='Title 1' sx={{ mt: 2 }} value={data.s2Title1} onChange={e => handleChange({ s2Title1: e })}/>
            <Input2 placeholder='Title 2' sx={{ ml: 4 }} value={data.s2Title2} onChange={e => handleChange({ s2Title2: e })}/>
            <Input2 placeholder='Part of Title 2 to be in secondary color' sx={{ ml: 4 }} value={data.s2Title2S} onChange={e => handleChange({ s2Title2S: e })}/>
            <br/>
            <Input2 multiline placeholder='Description' sx={{ mt: 2 }} value={data.s2Desc} onChange={e => handleChange({ s2Desc: e })}/>
            <br/>
            <Button 
            variant="contained" 
            onClick={() => {
                imgIndex.current = 1;
                inpRef.current?.click();
            }} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 2 }}>Select image</Button>
        </React.Fragment>
    ));

    const Accommodation = React.useMemo(() => (
        <div className="tab-pane" id="chicken2" role="tabpanel" aria-labelledby="chicken-tab">
            {data.s32List?.map((obj, i) => (
                <div className="row pt-3" style={{ marginTop: 46 }} key={i}>
                    <div className="col-md-5 animate-box fadeInUp animated">
                        {obj.img.length === 1 ? 
                        <div className="item" key={0}>
                            <div className="position-re o-hidden">
                                <img src={obj?.img[0]} className="img-fluid trs w-100" alt="banner"/>
                                <Button 
                                variant="contained" 
                                onClick={() => removeAccListImg(i, 0)} 
                                sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 1 }}>Remove</Button>
                            </div>
                        </div>
                        :
                        <Slider {...settings}>
                            {obj?.img?.map((img, x) => (
                                <div className="item" key={x}>
                                    <div className="position-re o-hidden">
                                        <img src={img} className="img-fluid trs w-100" alt="banner"/>
                                        <Button 
                                        variant="contained" 
                                        onClick={() => removeAccListImg(i, x)} 
                                        sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 1 }}>Remove</Button>
                                    </div>
                                </div>
                            ))}
                        </Slider>}
                    </div>
                    <div className="offset-md-1 col-md-6 mb-30">
                        <div className="section-subtitle">{obj?.subTitle}</div>
                        <div className="section-title">{obj?.title}</div>
                        <p>{obj?.desc}</p>
                    </div>
                    <div className="col-md-12" style={{ marginTop: 26 }}>
                        <Input2 placeholder='Subtitle' value={obj?.subTitle} onChange={e => handleListChange(i, 'subTitle', e)}/>
                        <Input2 placeholder='Title' sx={{ ml: 4 }} value={obj?.title} onChange={e => handleListChange(i, 'title', e)}/>
                        <Input2 multiline placeholder='Description' sx={{ mt: 2 }} value={obj?.desc} onChange={e => handleListChange(i, 'desc', e)}/>
                        <br/> 
                        <Button 
                        variant="contained" 
                        onClick={() => refArr.current[i]?.click()} 
                        sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 2 }}>Select image</Button>
                        <Button 
                        variant="contained" 
                        onClick={() => removeFromList2(i)} 
                        sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 2, ml: 4 }}>Remove</Button>
                        <input ref={ref => refArr.current[i] = ref} id='file-input' type='file' accept='image/*' style={{ display: 'none' }} onChange={e =>  handleListImage(i, e)}/>
                    </div>
                </div>
            ))}
            <Button 
            variant="contained" 
            onClick={addToList2} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 6 }}>Add new section</Button>
        </div>
    ));

    const packages = React.useMemo(() => (
        <div className="tab-pane active" id="duck2" role="tabpanel" aria-labelledby="duck-tab">
            {data.s31List.map((obj, i) => (
                <div className="row pt-3" key={i}>
                    <div className="col-md-6 mb-30">
                        <Input2 placeholder='Title' sx={{ mt: 2 }} value={obj.title} onChange={e => handleList1(i, 'title', e)}/>
                        <Input2 placeholder='Description' sx={{ mt: 2 }} value={obj.desc} onChange={e => handleList1(i, 'desc', e)}/>
                        <Input2 placeholder='Price' sx={{ mt: 2 }} value={obj.price} onChange={e => handleList1(i, 'price', e)}/>
                        <ul style={{ color: "#676977", fontWeight: 400, marginTop: 6 }}>
                            {obj.list?.map((obj, x) => (
                                <li key={x} style={{ marginLeft: -22 }}>
                                    {obj}
                                    <IconButton onClick={() => removeFroms1SubList(i, x)}>
                                        <DeleteForever sx={{ color: '#ABABAB' }}/>
                                    </IconButton>
                                </li>
                            ))}
                        </ul>
                        <Button 
                        variant="contained" 
                        onClick={() => adds1SubList(i)} 
                        sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 2 }}>Add new list</Button>
                        <Button 
                        variant="contained" 
                        onClick={() => {
                            imgIndex.current = 2;
                            listIndex.current = i;
                            inpRef.current?.click();
                        }}
                        sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 2, ml: 2 }}>Select image</Button>
                        <Button 
                        variant="contained" 
                        onClick={() => removeFromList1(i)} 
                        sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 2, ml: 2 }}>Delete</Button>
                    </div>
                    <div className="col-md-5 animate-box fadeInUp animated">
                        {obj.img.length === 1 ? 
                        <div className="item" key={0}>
                            <div className="position-re o-hidden">
                                <img src={obj?.img[0]} className="img-fluid trs w-100" alt="banner"/>
                                <Button 
                                variant="contained" 
                                onClick={() => removeS3ListImg(i, 0)} 
                                sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 1 }}>Remove</Button>
                            </div>
                        </div>
                        :
                        <Slider {...settings}>
                            {obj?.img?.map((img, x) => (
                                <div className="item" key={x}>
                                    <div className="position-re o-hidden">
                                        <img src={img} className="img-fluid trs w-100" alt="banner"/>
                                        <Button 
                                        variant="contained" 
                                        onClick={() => removeS3ListImg(i, x)} 
                                        sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 1 }}>Remove</Button>
                                    </div>
                                </div>
                            ))}
                        </Slider>}
                    </div>
                </div>
            ))}
            <Button 
            variant="contained" 
            onClick={addToList1} 
            sx={{ color: '#FFFFFF', borderRadius: 2, backgroundColor: '#2e2d31', mt: 2 }}>
                <Add/>
            </Button>
        </div>
    ));

    const sect3 = React.useMemo(() => (
        <div className="tab-pane" id="beef2" role="tabpanel" aria-labelledby="beef-tab">
            <Section3/>
        </div>
    ));

    const section4 = React.useMemo(() => (
        <React.Fragment>
            <Typography variant='h5' sx={{ color: '#585858', fontWeight: 600, fontFamily: 'inherit', mb: 6, mt: 6 }}>Section 4</Typography>
            <Box width='95%'>
                <section className="tours1 section-padding bg-lightnav pt-5 index2" data-scroll-index="1">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 tab-container">
                                <ul className="nav nav-tabs nav-justified" role="tablist">
                                    <li className="nav-item">
                                        <a style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className="nav-link active" data-toggle="tab" href="#duck2" role="tab" aria-controls="duck2" aria-selected="true" aria-expanded="true">
                                            <LuggageIcon sx={{ color: 'inherit' }}/> &nbsp; Umrah Package
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className="nav-link" data-toggle="tab" href="#beef2" role="tab" aria-controls="beef2" aria-selected="false">
                                            <CalendarMonth sx={{ color: 'inherit' }}/> &nbsp; Umrah Schedule
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} className="nav-link" data-toggle="tab" href="#chicken2" role="tab" aria-controls="chicken2" aria-selected="false">
                                            <ApartmentIcon sx={{ color: 'inherit' }}/> &nbsp; Accommodation
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content mt-3">
                                    {packages}
                                    {sect3}
                                    {Accommodation}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Box>
        </React.Fragment>
    ));

    return (

        <Box className='admin-div-body'>
            {loading?<LinearProgress sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 10000 }}/>:null}
            {section1}
            {section2}
            {section4}
            <input ref={inpRef} id='file-input' type='file' accept='image/*' style={{ display: 'none' }} onChange={e =>  handleImage(e)}/>
            <Button 
            variant="contained" 
            onClick={async() => {
                setLoading(true);
                await saveUmrahData(data);
                setLoading(false);
            }} 
            sx={{ color: '#cba235', borderRadius: 30, backgroundColor: '#2e2d31', position: 'absolute', bottom: 20, right: 20, p: 3.5, fontWeight: 'bold' }}>Save</Button>
            <Box height={200}/>
        </Box>

    );

}

export default React.memo(Umrah);